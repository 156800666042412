import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Auth.css'; // Import the CSS file

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // General error message
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous error messages
    setErrorMessage('');

    try {
      const response = await axios.post('http://127.0.0.1:8000/admin_console/login/', {
        email,
        password,
      });
      
      // Save token and navigate on success
      localStorage.setItem('token', response.data.token);
      navigate('/dashboard');
    } catch (err) {
      // Check if the error has a response and capture the message
      if (err.response && err.response.data) {
        // Assuming the backend returns a message in 'error' field
        setErrorMessage(err.response.data.error || 'An unknown error occurred');
      } else {
        setErrorMessage('An error occurred while connecting to the server');
      }
    }
  };

  return (
    <div className="container">
      <h2>Login</h2>
      
      {/* Display error message */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
        <a href="/forgot-password" className="forgot-password">Forgot Password?</a>
      </form>
    </div>
  );
};

export default Login;
