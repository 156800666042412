import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const AccVerification = () => {
  const { token } = useParams(); // Extract token from URL
  const [message, setMessage] = useState('Verifying...');
  const [error, setError] = useState(''); // State for error messages

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const response = await axios.post(`http://127.0.0.1:8000/admin_console/verify-account/`, null, {
          params: { token } // Use params to send the token
        });
        setMessage(response.data.detail || 'Account verified successfully!'); // Set success message
      } catch (err) {
        console.error("Verification Error:", err); // Log the error for debugging
        const errorMessage = err.response?.data?.detail || 'An unexpected error occurred. Please try again.'; // Get error message from response
        setError(errorMessage); // Set error message
        setMessage(''); // Clear the verifying message
      }
    };

    verifyAccount();
  }, [token]);

  return (
    <div>
      <h1>Account Verification</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Display error messages */}
      <p>{message}</p>  {/* Display success or status messages */}
    </div>
  );
};

export default AccVerification;
