import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
  const { token } = useParams();  // Ensure this is correct
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');  // State for confirmation password
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');  // State for error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');  // Reset any previous error messages

    // Check if the passwords match
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const url = `http://127.0.0.1:8000/admin_console/reset-password/?token=${token}`;
      const response = await axios.post(url, { new_password: newPassword });
      setMessage(response.data.Message || 'Password reset successfully.');
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 'Error resetting password. Please try again.';
      setMessage(errorMessage);
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Display error messages */}
      {message && <p>{message}</p>}  {/* Display success messages */}
    </div>
  );
};

export default ResetPassword;
